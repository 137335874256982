<template>
  <div class="accuracy-radio">
    <input
      class="input"
      type="radio"
      :name="name"
      :value="item.id"
      v-model="computedValue"
      :id="radioId"
      hidden
    />
    <label :for="radioId" class="label">
      <span class="label__content">
        <span class="label__content-text">
          {{ item.name }}
        </span>
        <span class="label__content-description" v-if="item.chars">
          <accuracy-radio-chars :chars="item.chars" :id="item.id" />
        </span>
      </span>
    </label>
    <accuracy-radio-tooltip v-if="item.description" :description="item.description" />
  </div>
</template>

<script>
import AccuracyRadioTooltip from '@/components/smart/DeepSettings/AccuracyForm/AccuracyRadioTooltip'
import AccuracyRadioChars from '@/components/smart/DeepSettings/AccuracyForm/AccuracyRadioChars'
export default {
  components: { AccuracyRadioChars, AccuracyRadioTooltip },
  props: {
    item: {
      type: Object,
      required: true
    },
    currentValue: {
      type: [String, Number],
      default: null
    },
    name: {
      type: [String, Number],
      default: null
    }
  },
  name: 'AccuracyRadio',
  computed: {
    radioId() {
      const { name, item } = this
      return `${name}--${item.id}`
    },
    computedValue: {
      get() {
        return this.currentValue
      },
      set(val) {
        this.$emit('updateValue', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.accuracy-radio

  display: flex
  align-items: center
  justify-content: space-between
  max-width: rem(250)
  &:first-child
    min-height: rem(64)
  &:not(:first-child)
    min-height: rem(96)
  &:not(:last-child)
    box-shadow: inset 0 rem(-1) 0 $ec
  .input
    &:checked
      + .label
          &:before
            box-shadow: none
            border-color: $red
            border-width: rem(4)
          .label
            &__content
              color: $black
              font-weight: bold
              &-description
                &::v-deep
                  .description
                    &__item
                      &-val
                        font-weight: bold
  .label
    display: flex
    align-items: center
    &__content
      cursor: pointer
      display: grid
      gap: rem(8)

      line-height: 100%
      color: $light-black
      &-text
        font-size: rem(16)
      &-description
        font-size: rem(14)
    &:before
      cursor: pointer
      transition: .3s
      position: relative
      display: block
      content: ""
      height: rem(18)
      width: rem(18)
      border-radius: 50%
      margin-right: rem(22)
      border: rem(1) solid $white
      background: $white
      box-sizing: border-box
      box-shadow: 0 0 0 rem(1) $cool_A
    @media(any-hover: hover)
      &:hover
        &:before
          background: $red
          border-width: rem(6)
          border-color: $white
          box-shadow: 0 0 0 rem(1) $black_C
</style>
