<template>
  <div class="accuracy-data-step">
    <div v-for="item in extendedItems" :key="item.id" class="step-item">
      <accuracy-form-title> {{ item.name }} </accuracy-form-title>
      <div class="fields">
        <accuracy-radio
          v-for="option in item.options"
          :name="item.id"
          :item="option"
          :key="option.id"
          :current-value="fields[item.id]"
          @updateValue="updateValue($event, item.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AccuracyRadio from '@/components/smart/DeepSettings/AccuracyForm/AccuracyRadio'
import AccuracyFormTitle from '@/components/smart/DeepSettings/AccuracyForm/AccuracyFormTitle'
import { cloneDeep } from 'lodash'

const unsettedOption = {
  id: null,
  name: 'Нет'
}

export default {
  name: 'AccuracyForm',
  components: { AccuracyFormTitle, AccuracyRadio },
  methods: {
    ...mapActions('moderator', {
      updateCalculationAccuracyParam: 'updateCalculationAccuracyParam'
    }),
    updateValue(val, param) {
      this.updateCalculationAccuracyParam({
        param,
        val
      })
    }
  },
  computed: {
    ...mapState('moderator', {
      fields: state => state.calculationAccuracy,
      accuracyStepData: state => state.accuracyStepData
    }),
    extendedItems() {
      const clonedItems = cloneDeep(this.accuracyStepData)
      return clonedItems.map(item => {
        return {
          ...item,
          options: [
            {
              ...unsettedOption
            },
            ...item.options
          ]
        }
      })
    }
  }
}
</script>

<style scoped lang="sass">
.accuracy-data-step
  display: flex
  gap: rem(50)
  flex-wrap: wrap
  .step-item
    width: rem(300)
    max-width: 100%
    &:not(:last-child)
      box-shadow: inset rem(-1) 0 0 $ec
</style>
