<template>
  <span class="chars">
    <span class="chars__item" v-for="(item, i) in chars" :key="`item--${id}--char--${i}`">
      <span class="chars__item-key">{{ item.name }}</span>
      <span class="chars__item-val" v-if="item.value">{{ item.value }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    chars: {
      type: Array,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  name: 'AccuracyRadioChars'
}
</script>

<style scoped lang="sass">
.chars
  display: grid
  row-gap: rem(6)
  width: rem(162)
  font-weight: normal
  &__item
    display: flex
    align-items: center
    justify-content: space-between
</style>
