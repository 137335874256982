<script>
export default {
  name: 'AccuracyFormTitle',
  render: function (createElement) {
    return createElement('div', { class: 'title' }, this.$slots.default)
  }
}
</script>

<style scoped lang="sass">
.title
  color: $black
  font-weight: bold
  font-size: rem(16)
  line-height: 150%
  max-width: rem(250)
  margin-bottom: rem(24)
  min-height: rem(72)
</style>
