<template>
  <div class="calculation-accuracy-step">
    <accuracy-form />
    <steps-nav :current-step="currentStep" @prev="prev" @next="next" />
  </div>
</template>

<script>
import AccuracyForm from '@/components/smart/DeepSettings/AccuracyForm'
import StepsNav from '@/components/smart/DeepSettings/StepsNav'
export default {
  name: 'CalculationAccuracyStep',
  props: {
    currentStep: {
      type: String,
      required: true
    }
  },
  components: { StepsNav, AccuracyForm },
  methods: {
    next() {
      this.$emit('next')
    },
    prev() {
      this.$emit('prev')
    }
  }
}
</script>

<style scoped lang="sass">
.calculation-accuracy-step
  display: grid
  gap: rem(64)
</style>
