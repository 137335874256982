<template>
  <tippy arrow="true" maxWidth="316px" interactive>
    <template #trigger>
      <span class="icon">
        <inline-svg :src="require('@/assets/img/icons/deep-settings/accuracy-tooltip.svg')" />
      </span>
    </template>
    <div class="accuracy-tooltip__content" v-html="description"></div>
  </tippy>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  props: {
    description: {
      type: String,
      required: true
    }
  },
  components: {
    InlineSvg
  },
  name: 'AccuracyRadioTooltip'
}
</script>

<style scoped lang="sass">
.accuracy-tooltip
  &__content
    padding: rem(11) rem(7)
    box-sizing: border-box
    display: grid
    gap: rem(12)
    text-align: left
    color: $white
    font-size: rem(12)

    font-weight: bold
    &::v-deep
      p, ul
        margin: 0
        padding: 0
      p
        line-height: 150%
      ul
        list-style: none
        line-height: 133%
        li
          display: flex
          &:not(:last-child)
            margin-bottom: rem(6)
          &:before
            content: ""
            position: relative
            display: block
            height: rem(4)
            width: rem(4)
            background: $red
            border-radius: 50%
            margin: rem(6) rem(16) 0 0
.icon
  transition: .3s
  color: $cool_A
  line-height: 0
  cursor: help
  @media(any-hover: hover)
    &:hover
      color: $black_C
</style>
